<template>
  <div class="py-3 text-gray-500">フッター</div>
</template>

<script language="ts">
import { defineComponent } from "vue"
export default defineComponent({
  name: "PublicFooter",
  props: {},
})
</script>
