
import { reactive, computed, defineComponent, onMounted, WritableComputedRef } from "vue"
import { productsState, getAndSetProduct } from "@/composables/useProducts"
import { Product } from "@/types"
import { getAndSetProductReviews, reviewsState } from "@/composables/useReviews"
import PublicButton from "@/components/public/PublicButton.vue"
import PublicHeader from "@/components/public/PublicHeader.vue"
import PublicFooter from "@/components/public/PublicFooter.vue"

interface ComponentState {
  product: Product | null
}

export default defineComponent({
  name: "itemDetails",

  components: {
    PublicButton,
    PublicHeader,
    PublicFooter,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  setup: function (props) {
    const state = reactive<ComponentState>({
      product: null,
    })

    onMounted(() => {
      getAndSetProduct(props.id)
      getAndSetProductReviews(props.id)
      console.log("id=", props.id)
    })

    const anyLoading: WritableComputedRef<boolean> = computed(() => productsState.loading)

    return {
      state,
      anyLoading,
      product: computed(() => productsState.product),
      reviews: computed(() => reviewsState.reviews),
      reviewsLoading: computed(() => reviewsState.loading),
    }
  },
})
